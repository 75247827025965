/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'folder-x': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M.54 3.87L.5 3a2 2 0 012-2h3.672a2 2 0 011.414.586l.828.828A2 2 0 009.828 3h3.982a2 2 0 011.992 2.181L15.546 8H14.54l.265-2.91A1 1 0 0013.81 4H2.19a1 1 0 00-.996 1.09l.637 7a1 1 0 00.995.91H9v1H2.826a2 2 0 01-1.991-1.819l-.637-7a2 2 0 01.342-1.31zm6.339-1.577A1 1 0 006.172 2H2.5a1 1 0 00-1 .981l.006.139q.323-.119.684-.12h5.396z"/><path pid="1" d="M11.854 10.146a.5.5 0 00-.707.708L12.293 12l-1.146 1.146a.5.5 0 00.707.708L13 12.707l1.146 1.147a.5.5 0 00.708-.708L13.707 12l1.147-1.146a.5.5 0 00-.707-.708L13 11.293z"/>',
    },
});
